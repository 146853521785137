<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <brand-form
      v-if="brand.id"
      ref="brand-form"
      :is-edit="isEdit"
    />
    <div
      v-else
      class="py-4"
    >
      <div v-if="!error">
        <b-row>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton
              type="checkbox"
              class="rounded"
            />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <component-not-found
          title="Brand List"
          :button-link="{ name: 'settings-catalog-brands'}"
        />
      </div>
    </div>
  </div>

</template>

<script>

import {
  BRow, BCol, BSkeleton, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import UserForm from '@/views/settings/users/components/UserForm.vue'
import store from '@/store'
import BrandForm from '../components/BrandsForm.vue'
import config from '../brandConfig'

export default {
  name: 'BrandUpdate',
  components: {
    UserForm,
    BrandForm,
    BRow,
    BCol,
    BSkeleton,
  },
  inject: ['brandModuleName'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['brand-form']) this.$refs['brand-form'].showConfirmationModal(next)
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    brand() {
      return this.$store.state[this.brandModuleName].brandForm
    },
    ...mapGetters('listModule', ['getStatus']),
  },
  mounted() {
    this.$store.dispatch(`${this.brandModuleName}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const brand = this.mappingFields(Object.keys(this.fields), data, {
        id: data.id,
      })
      this.$store.commit(`${this.brandModuleName}/SET_FORM`, brand)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_BRAND_FORM_CLONE`, brand)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.brandModuleName}/SET_FORM`, {})
  },
  methods: {
    isEditAble() {
      return this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style>

</style>
